.map-container {
  border: 2px solid black;
  width: 1200px;
  height: 600px;
  overflow: hidden;
  position: relative; /* permet de positionner les enfants en absolu par rapport à lui */
  z-index: 1;
}

.leaflet-custom-zoom {
  position: absolute;
  bottom: 10px;
  right: 10px;
  z-index: 1000;
}

.leaflet-container {
  height: 100%;
}

@media screen and (max-width: 320px) {
  .map-container {
    width: 280px;
    height: 300px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@media screen and (min-width: 321px) and (max-width: 375px) {
  .map-container {
    width: 350px;
    height: 300px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@media screen and (min-width: 376px) and (max-width: 425px) {
  .map-container {
    width: 400px;
    height: 400px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@media screen and (min-width: 426px) and (max-width: 768px) {
  .map-container {
    width: 700px;
    height: 400px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
